import http from '@/libs/http'
export default class LicUsersService {
  getUsers () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-usuarios`)
  }

  findUser (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-usuarios/${id}`)
  }
}
