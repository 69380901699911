<template>
  <div class="flex justify-between mt-5 mb-3">
    <Button label="Atras"
            icon="pi pi-arrow-left"
            class="mr-2 p-button-danger"
            @click="$router.go(-1)"
    />
    <Button label="Crear Tarea"
            icon="pi pi-plus"
            class="mr-2 p-button-success"
            @click="$router.push({name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas', params: { id: $route.params.licCarpetasDocumentosId}})"
    />
  </div>

  <DataTable :value="dataLoad" responsiveLayout="scroll">
    <template #empty>
      No hay tareas asignadas a usted o creadas por usted
    </template>
    <template #header>
      <div class="flex justify-between">
        <Button type="button" icon="pi pi-filter-slash" label="Limpiar" class="p-button-outlined"
                @click="clearFilter()"/>
        <div class="flex gap-4">
          <Dropdown v-model="estado"
                    :options="optionsStates"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Seleccione un estado"
                    :filter="true"
                    filterPlaceholder="Buscar"
          />
          <Dropdown v-model="usuario"
                    :options="optionsUsers"
                    optionLabel="fullName"
                    optionValue="id"
                    placeholder="Seleccione un usuario"
                    :filter="true"
                    filterPlaceholder="Buscar"
          />
        </div>
      </div>
    </template>
    <Column field="id" header="Observaciones">
      <template #body="{data}">
        <span>{{ data.observaciones }}</span>
      </template>
    </Column>
    <Column field="id" header="Fecha Inicio">
      <template #body="{data}">
        <span>{{ data.fechaInicio }}</span>
      </template>
    </Column>
    <Column field="id" header="Fecha Fin">
      <template #body="{data}">
        <span>{{ data.fechaFin }}</span>
      </template>
    </Column>
    <Column field="id" header="Estado">
      <template #body="{data}">
<!--        <span>{{ data.estado }}</span>-->
        <Dropdown v-model="data.estado"
                  :options="optionsStatesDatatable"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Seleccione un estado"
                  @change="onChangeEstado(data)"
        />
      </template>
    </Column>
    <Column field="id" header="Responsable">
      <template #body="{data}">
        <span>{{ data.Usuario.fullName }}</span>
      </template>
    </Column>
    <Column header="Acciones">
      <template #body="{data}">
        <div class="flex justify-start" style="align-items: center">
          <Button icon="pi pi-pencil"
                  class="mr-2"
                  style="height: 40px; width: 40px;"
                  @click="$router.push({ name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas.editar', params: { id: data.id}})"
                  v-tooltip.top="'Editar Tarea'"
          />
          <i class="pi pi-comments p-text-secondary" style="font-size: 1.5rem; cursor: pointer" v-badge.danger="data.mensajescount" @click="openMessages(data.id)"></i>
        </div>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="offset"
             :rows="10"
             :totalRecords="totalRecords"
             :rowsPerPageOptions="rowsPerPageOptions"
             @page="onPage($event)"
  />
  <Sidebar id="sidebar" v-model:visible="visibleRight" position="right" style="width: 40rem;height: 100%;">
    <div style="height: 91vh;" class="flex flex-col justify-end p-2 gap-2">

      <div id="papamensajes" ref="papamensajes" class="scroll" style="height: 85vh; overflow-y: auto">
        <div id="mensajes" style="height: 100%;" class="flex flex-col justify-end p-2 gap-2">
          <div v-for="(item, index) in messages" :class="`flex flex-col ${item.createdBy === getUser.id ? 'items-end' : 'items-start' }`" :key="index">
            <span style="font-size: 12px">{{$h.capitalize(item.Creador.fullName)}}</span>
            <span :class="`${item.createdBy === getUser.id ? 'caja-mensaje own' : 'caja-mensaje invited' }`">{{ item.mensaje }}</span>
          </div>
        </div>
      </div>

      <form @submit="onSubmit">
        <div class="caja-texto flex gap-2 w-full ">
          <InputText
            v-model="model.mensaje"
            id="mensaje"
            type="text"
            class="w-full"
            placeholder="Escribe aqui tu mensaje"
          />
          <Button icon="pi pi-send"
                  style="height: 40px; width: 40px;"
                  type="submit"
                  v-tooltip.left="'Enviar'"
          />
        </div>
        <div class="text-red-400">
          {{errors.mensaje}}
        </div>
      </form>
    </div>
  </Sidebar>
  <Toast/>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import LicTareasService from '@/apps/pharmasan/licitaciones/services/LicTareasService'
import LicUsersService from '@/apps/pharmasan/licitaciones/services/LicUsersService'
import LicMensajesService from '@/apps/pharmasan/licitaciones/services/LicMensajesService'
import { useStore } from 'vuex'
import { useField, useForm } from 'vee-validate'
import { string, object } from 'yup'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'TareasHome',
  setup () {
    onMounted(async () => {
      getOnLoad()
      await getUsuarios()
    })
    // Variables
    const route = useRoute()
    const _LicTareasService = ref(new LicTareasService())
    const _LicUsersService = ref(new LicUsersService())
    const _LicMensajesService = ref(new LicMensajesService())
    const router = useRouter()
    const dataLoad = ref([])
    const usuario = ref(null)
    const estado = ref(null)
    const store = useStore()
    const filters = computed(() => {
      return {
        responsable: usuario.value,
        estado: estado.value,
        licCarpetasDocumentosId: route.params.licCarpetasDocumentosId
      }
    })
    const getUser = computed(() => {
      return store.getters['auth/getPeople']
    })
    const offset = ref(0)
    const limit = ref(10)
    const totalRecords = ref(0)
    const rowsPerPageOptions = computed(() => {
      return [5, 10, 30, 50, totalRecords.value]
    })
    const optionsUsers = ref([])
    const optionsStatesDatatable = ref([
      {
        id: 0,
        name: 'Pendiente'
      },
      {
        id: 1,
        name: 'En proceso'
      },
      {
        id: 2,
        name: 'Finalizado'
      },
      {
        id: 3,
        name: 'Cancelado'
      }
    ])
    const optionsStates = ref([
      {
        id: null,
        name: 'Seleccione una opcion'
      },
      {
        id: 0,
        name: 'Pendiente'
      },
      {
        id: 1,
        name: 'En proceso'
      },
      {
        id: 2,
        name: 'Finalizado'
      },
      {
        id: 3,
        name: 'Cancelado'
      }
    ])
    const visibleRight = ref(false)
    const validationSchema = object().shape({
      mensaje: string().required()
    })
    const { values: model, errors, handleSubmit, handleReset } = useForm({
      validationSchema
    })
    const idTarea = ref(null)
    const messages = ref([])
    const papamensajes = ref()
    useField('mensaje', null, { initialValue: '' })
    const toast = useToast()

    /** whatch **/
    watch(filters, (a) => {
      router.query = {
        offset: offset.value,
        limit: limit.value,
        filters: a
      }
      getOnLoad()
    })

    // Methods
    const getUsuarios = () => {
      _LicUsersService.value.getUsers().then(({ data }) => {
        optionsUsers.value.push({
          id: null,
          fullName: 'Seleccione una opcion'
        })
        optionsUsers.value.push(...data)
      })
    }

    const getOnLoad = () => {
      _LicTareasService.value.getTareas(limit.value, offset.value, filters.value).then(({ data }) => {
        dataLoad.value = data.rows
        totalRecords.value = data.count
      }).catch((error) => {
        console.log(error)
      })
    }
    const clearFilter = () => {
      usuario.value = null
      estado.value = null
    }

    const onPage = (event) => {
      console.log(event)
      limit.value = event.rows
      getOnLoad()
    }

    const openMessages = (id) => {
      console.log(id)
      idTarea.value = id
      _LicMensajesService.value.findMessagesByIdTarea({ tareaId: idTarea.value }).then(({ data }) => {
        messages.value = data.rows
        visibleRight.value = !visibleRight.value
        setTimeout(() => {
          var scrollito = document.getElementById('papamensajes')
          scrollito.scrollTop = scrollito.scrollHeight
        }, 0)
      })
    }

    const getMessages = () => {
      _LicMensajesService.value.findMessagesByIdTarea({ tareaId: idTarea.value }).then(({ data }) => {
        messages.value = data.rows
        getOnLoad()
      })
    }

    const onSubmit = handleSubmit((values) => {
      values.tareaId = idTarea.value
      _LicMensajesService.value.saveMessage(values).then((data) => {
        if (data.status === 200) {
          getMessages()
          handleReset()
        } else {
          console.log(data)
        }
      })
    })

    const onChangeEstado = (data) => {
      _LicTareasService.value.updateTarea(data.id, {
        estado: data.estado
      }).then(({ data }) => {
        if (data.updated) {
          toast.add({
            severity: 'success',
            summary: 'Exito',
            detail: 'Tarea actualizada con exito',
            life: 3000
          })
          getOnLoad()
        }
      })
    }

    return {
      onChangeEstado,
      dataLoad,
      usuario,
      estado,
      filters,
      offset,
      clearFilter,
      onPage,
      totalRecords,
      rowsPerPageOptions,
      optionsUsers,
      optionsStates,
      visibleRight,
      openMessages,
      getUser,
      model,
      errors,
      onSubmit,
      getMessages,
      messages,
      papamensajes,
      optionsStatesDatatable
    }
  }
}
</script>

<style scoped>
.caja-mensaje {
  font-size: 18px;
  color: black;
  padding: 8px;
  max-width: 80%
}

.own {
  background-color: #94D8F6;
  border-radius: 5px 5px 20px 5px;
  -moz-border-radius: 5px 5px 20px 5px;
  -webkit-border-radius: 5px 5px 20px 5px;
}

.invited {
  background-color: #98E0AD;
  border-radius: 5px 5px 5px 20px;
  -moz-border-radius: 5px 5px 5px 20px;
  -webkit-border-radius: 5px 5px 5px 20px;
}
</style>
