import http from '@/libs/http'
export default class LicUsersService {
  getTareas (limit = 10, offset = 0, filters = {}) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-tareas/paginate?limit=${limit}&offset=${offset}`, {
      params: { filters },
      headers: { loading: true }
    })
  }

  findTarea (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-tareas/paginate`, {
      params: {
        filters: {
          id
        }
      },
      headers: { loading: true }
    })
  }

  saveTarea (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-tareas`, payload, {
      headers: {
        loading: true
      }
    })
  }

  updateTarea (id, payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-tareas/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
