import http from '@/libs/http'
export default class LicMensajesService {
  findMessagesByIdTarea (filters) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-mensajes`, {
      params: {
        filters
      },
      headers: { loading: true }
    })
  }

  saveMessage (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-mensajes`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
